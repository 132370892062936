@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@500;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: clip;
  width: auto;
}

/* .work-title {
  font-family: "Caveat", cursive;
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
} */
