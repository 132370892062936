@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, -1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(0, -1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, -1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, -1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes jello {
  0%,
  11.1%,
  100% {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.195312deg) skewY(-0.195312deg);
  }
}

@keyframes bounce {
  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 1;
    transform: translate3d(0px, -10px, 0px);
  }
  60% {
    opacity: 1;
    transform: translate3d(0px, 10px, 0px);
  }
  75% {
    transform: translate3d(0px, -10px, 0px);
  }
  90% {
    transform: translate3d(0px, 5px, 0px);
  }
  100% {
    transform: none;
  }
}

.simon {
  display: inline-block;
  white-space: pre;
  animation-fill-mode: both;
  backface-visibility: visible;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: flip;
  color: #925e78;
}

.simon2 {
  display: inline-block;
  white-space: pre;
  animation-fill-mode: both;
  backface-visibility: visible;
  animation-duration: 1200ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: flip;
  color: #925e78;
}

.simon3 {
  display: inline-block;
  white-space: pre;
  animation-fill-mode: both;
  backface-visibility: visible;
  animation-duration: 1400ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: flip;
  color: #925e78;
}

.jello-image {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: jello;
}

.bouncy-image {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-name: bounce;
}

.bouncy-image:hover {
  animation-play-state: paused;
}

/* effect-shine */
a.effect-shine:hover {
  -webkit-mask-image: linear-gradient(-75deg, rgba(22, 0, 150, 0.774) 30%, rgb(160, 0, 192) 50%, rgba(24, 0, 68, 0.678) 70%);
  mask-image: linear-gradient(-75deg, rgba(22, 0, 150, 0.774) 30%, rgb(160, 0, 192) 50%, rgba(24, 0, 68, 0.678) 70%);
  -webkit-mask-size: 200%;
  mask-size: 200%;
  animation: shine 2s infinite;
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  
  to {
    -webkit-mask-position: -50%;
  }
}

@keyframes shine {
  from {
    mask-position: 150%;
  }
  
  to {
    mask-position: -50%;
  }
}